
import Vue from 'vue';

type Empty = Record<string, never>;

interface Props {
    account: string,
    color: string
}

export default Vue.extend<Empty, Empty, Empty, Props>({
    props: {
        color: {
            type: String,
            default: 'blue1',
        },
        account: {
            type: String,
            default: '',
        },
    },
});
