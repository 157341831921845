

import SwitchControl from 'Control/Switch.vue';
import Checkbox from 'Control/Checkbox.vue';
import Search from 'Control/Search.vue';
import Icon from 'UI/Icon.vue';
import Indicator from 'UI/Indicator.vue';
import Faq from 'UI/Faq.vue';

export default {
    name: 'UiCheck',
    data() {
        return { console };
    },
    components: {
        SwitchControl,
        Icon,
        Checkbox,
        Indicator,
        Faq,
        Search,
    },
};
